import { invariant } from "@epic-web/invariant";
import { useSearchParams } from "@remix-run/react";
import type { ReactNode } from "react";
import React, { createContext, useContext, useEffect, useState } from "react";
import isEqual from "react-fast-compare";
import * as R from "remeda";

export const SearchParamsManagerContext = createContext<
	| undefined
	| {
			setSearchParam;
			searchParams;
			setSearchParamImmediate;
	  }
>(undefined);

type ParamQueueEntry = {
	key: string;
	type: "string" | "array";
	value: string | string[];
};
export const SearchParamsManager = ({
	searchParams = [],
	children,
}: {
	children?: ReactNode;
	searchParams?: string[];
}) => {
	const [paramQueue, setParamQueue] = useState<ParamQueueEntry[]>([]);
	const [_searchParams, setSearchParams] = useSearchParams();

	const _setValue = (
		key,
		value,
		searchParamValue: URLSearchParams | undefined = undefined,
	) => {
		if (searchParamValue) {
			searchParamValue.set(key, value);
			return searchParamValue;
		}
		setSearchParams(
			(oldParams) => {
				oldParams.set(key, value);
				return oldParams;
			},
			{ replace: true },
		);
		return undefined;
	};

	const _deleteValue = (
		key,
		searchParamValue: URLSearchParams | undefined = undefined,
	) => {
		if (searchParamValue) {
			searchParamValue.delete(key);
			return searchParamValue;
		}
		setSearchParams((oldParams) => {
			oldParams.delete(key);
			return oldParams;
		});
		return undefined;
	};

	const _setValues = (
		key,
		values,
		searchParamValue: URLSearchParams | undefined = undefined,
	) => {
		if (searchParamValue) {
			searchParamValue.delete(key);
			for (const value of values) {
				searchParamValue.append(key, value.toString());
			}
			return searchParamValue;
		}
		setSearchParams((oldParams) => {
			oldParams.delete(key);
			for (const value of values) {
				oldParams.append(key, value.toString());
			}
			return oldParams;
		});

		return undefined;
	};

	useEffect(() => {
		if (paramQueue.length && _searchParams) {
			let searchParams = new URLSearchParams(_searchParams) as
				| URLSearchParams
				| undefined;
			for (const paramQueueEntry of paramQueue) {
				if (paramQueueEntry.value === undefined) {
					searchParams = _deleteValue(paramQueueEntry.key, searchParams);
				} else {
					if (paramQueueEntry.type === "array") {
						searchParams = _setValues(
							paramQueueEntry.key,
							paramQueueEntry.value,
							searchParams,
						);
					} else {
						searchParams = _setValue(
							paramQueueEntry.key,
							paramQueueEntry.value,
							searchParams,
						);
					}
				}
			}
			setSearchParams(searchParams);
			setParamQueue([]);
		}
	}, [
		_deleteValue,
		_searchParams,
		_setValue,
		_setValues,
		paramQueue,
		setSearchParams,
	]);

	const setSearchParam = (key, value, type: "string" | "array" = "string") => {
		setParamQueue((last) => {
			const stuff = R.uniqueWith(
				[
					...last,
					{
						type,
						key,
						value,
					},
				],
				isEqual,
			);

			return stuff;
		});
	};

	const setSearchParamImmediate = (
		key,
		value,
		type: "string" | "array" = "string",
	) => {
		const paramQueueEntry = { key, value, type };
		if (paramQueueEntry.value === undefined) {
			_deleteValue(paramQueueEntry.key);
		} else {
			if (paramQueueEntry.type === "array") {
				_setValues(paramQueueEntry.key, paramQueueEntry.value);
			} else {
				_setValue(paramQueueEntry.key, paramQueueEntry.value);
			}
		}
	};

	const context = {
		setSearchParam,
		searchParams: _searchParams,
		setSearchParamImmediate,
	};

	return (
		<SearchParamsManagerContext.Provider value={context}>
			{children}
		</SearchParamsManagerContext.Provider>
	);
};

export const useSearchParamsManager = () => {
	const d = useContext(SearchParamsManagerContext);
	invariant(
		d,
		"SearchParamsManagerContext is undefined. Did you forget to wrap your component in <SearchParamsManager>?",
	);
	return d;
};
